import {BrowserRouter}from "react-router-dom";
import Router from "./route/Router";
import 'react-toastify/dist/ReactToastify.css';
import "react-datepicker/dist/react-datepicker.css";
import {GlobalDebug} from "./components/console";
import React, {Component} from 'react';


class App extends Component {
    componentDidMount() {
        GlobalDebug(false)
    }

    render() {
        return (
            <BrowserRouter>
                <Router/>
            </BrowserRouter>
        );
    }
}


export default App;
