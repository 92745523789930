import React, {Component} from 'react';
import Axios from "axios";
import ApiUrl from "../../AppUrl/ApiUrl";
import moment from "moment";

class Mining extends Component {
    constructor() {
        super();
        this.state={
            token:"",loading:true,limit:"500",data:[]
        }
    }
    componentDidMount() {
        var token = localStorage.getItem("authtoken")
        if(token){
            this.setState({token:token})
            setTimeout(()=>{
                this.getPayments()
            },200)
        }else {
            this.props.history.push("/login")
        }
    }
    getPayments=()=>{
        var formd = new FormData()
        formd.append("token",this.state.token)
        formd.append("limit",this.state.limit)
        Axios.post(ApiUrl.baseurl+"auth/payments/mining",formd)
            .then(res=>{
                //console.log(res.data.data)
                if(res.data.data.length>0){
                    this.setState({data:res.data.data,loading:false})
                }else{
                    this.setState({loading:false})
                }
            })
            .catch(err=>{
                //
            })
    }
    render() {
        var tableview = this.state.data.map(res=>{
            return(
                <tr>
                    <td> <img src={ApiUrl.photoUrl+res.logo_img}/> {res.coin_symbol}</td>
                    <td style={{textAlign:"center"}}>{moment(res.created_at).format(("YYYY-MM-DD HH:mm:ss"))}</td>
                    <td className={res.status=="Success"?"text-success":
                        res.status=="Rejected"?
                            "text-danger":""} style={{textAlign:"center"}}>{res.status}</td>
                    <td style={{textAlign:"center"}}>{res.type}</td>
                    <td style={{textAlign:"center"}}>{res.amount} {res.coin_symbol}</td>
                    <td style={{textAlign:"right"}}>{res.trx==""||res.trx==null?
                        "Null"
                        :<>
                            <a target="_blank" href={res.explorer+"/tx/"+res.trx}>{res.trx.substring(0,10)}...</a>
                        </>
                    }</td>
                </tr>

            )
        })
        var mobilehistory=this.state.data.map(res=>{
            return(
                <>
                    <div className="mobilehistory">
                        <h6>{res.coin_symbol} <span className="float-end">
                            {res.type=="Transfer"?"-":"+"}{res.amount}</span></h6>
                        <p>{moment(res.created_at).format(("YYYY-MM-DD HH:mm:ss"))}
                            <span className={res.status=="Success"?"float-end text-success":
                                res.status=="Rejected"?
                                    "float-end text-danger":"float-end"}>{res.status}</span></p>
                        {
                            res.trx==""||res.trx==null?"":
                                <p>Trx <span><a className="float-end" target="_blank" href={res.explorer+"/tx/"+res.trx}>
                                    {res.trx.substring(0,10)}...</a></span>
                                </p>
                        }
                    </div>
                </>
            )
        })
        return (
            <div className="hdata table-responsive">
                <table className="table">
                    <thead>
                    <tr>
                        <th>Crypto</th>
                        <th style={{textAlign:"center"}}>Time</th>
                        <th style={{textAlign:"center"}}>Status</th>
                        <th style={{textAlign:"center"}}>Type</th>
                        <th style={{textAlign:"center"}}>Amount</th>
                        <th style={{textAlign:"right"}}>TxID</th>
                    </tr>
                    </thead>
                    <tbody>
                    {tableview}
                    </tbody>
                </table>

                {mobilehistory}

                {this.state.loading==true?
                    <div className="d-flex justify-content-center " style={{marginTop:"100px"}}>
                        <div className="spinner-border text-primary" role="status">
                            <span className="visually-hidden">Loading...</span>
                        </div>
                    </div>:""
                }
                {this.state.loading==false && this.state.data.length==0?
                    <p style={{marginTop:"100px",textAlign:"center"}}>
                        No Data
                    </p>
                    :""
                }

            </div>
        );
    }
}

export default Mining;