import React, {Component} from 'react';
import TopMenu from "../components/TopMenu";
import Footer from "../components/Footer";
import Axios from "axios";
import ApiUrl from "../AppUrl/ApiUrl";
import {toast} from "react-toastify";

class ChangePassword extends Component {
    constructor() {
        super();
        this.state={
            token:"",old:"",newp:"",cpass:"",loading:false
        }
    }

    componentDidMount() {
        var token = localStorage.getItem("authtoken")
        if(token){
            this.setState({token:token})
        }else {
            this.props.history.push("/login")
        }
    }

    old=(e)=>{
        this.setState({old:e.target.value})
    }
    newp=(e)=>{
        this.setState({newp:e.target.value})
    }
    cpass=(e)=>{
        this.setState({cpass:e.target.value})
    }

    errorMsg=(val)=>{
        toast.error(val, {
            theme: "colored",
            position: "top-center",
            autoClose: 2000,
            hideProgressBar: true,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
        });
    }
    successMsg=(val)=>{
        toast.info(val,  {
            theme: "colored",
            position: "top-center",
            autoClose: 1000,
            hideProgressBar: true,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
        });
    }

    submitData=()=>{
        var formd = new  FormData()
        formd.append("token",this.state.token)
        formd.append("old",this.state.old)
        formd.append("password",this.state.newp)
        formd.append("confirmpass",this.state.cpass)
        if(this.state.old!==""&&this.state.newp!==""){
            this.setState({loading:true})
            Axios.post(ApiUrl.baseurl+"auth/change-password",formd)
                .then(res=>{
                    if(res.data.success){
                        this.successMsg(res.data.success)
                    }else {
                        this.errorMsg(res.data.error)
                    }
                    this.setState({loading:false})
                })
                .catch(err=>{
                    //
                })
        }else {
            this.errorMsg("All input is required")
        }
    }

    render() {
        return (
            <div className="changepass">
                <TopMenu title="Change Password"/>
                <div className="row m-0">
                    <div className="col-md-3"></div>
                    <div className="col-md-6">
                        <div className="formda">
                            <h3>Change Password</h3>
                            <div className="inputfiled">
                                <br/>
                                <label>Current Password</label>
                                <input onChange={this.old} value={this.state.old} type="password"/>
                                <label>New Password</label>
                                <input onChange={this.newp} value={this.state.newp} type="password"/>
                                <label>New confirm password</label>
                                <input onChange={this.cpass} value={this.state.cpass} type="password"/>
                                <button disabled={this.state.loading}
                                        onClick={this.submitData}>{this.state.loading==true?"Loading...":"Submit"}</button>
                            </div>
                        </div>
                    </div>
                </div>
                <Footer/>
            </div>
        );
    }
}

export default ChangePassword;