import React, {Component} from 'react';
import TopMenu from "../components/TopMenu";
import {Link} from "react-router-dom";
import {toast} from "react-toastify";
import Axios from "axios";
import ApiUrl from "../AppUrl/ApiUrl";
import Footer from "../components/Footer";

function validateEmail(email) {
    var re = /\S+@\S+\.\S+/;
    return re.test(email);
}

class Register extends Component {
    constructor() {
        super();
        this.state={
            checkbox:false,
            email:"",password:"",confirmpass:"",refer_by:"",loading:false,
            form:false,code:"",dbcode:""
        }
    }
    componentDidMount() {
        var ref = sessionStorage.getItem("ref")
        if(ref){
            this.setState({refer_by:ref})
        }
        var token = localStorage.getItem("authtoken")
        if(token){
            this.props.history.push("/wallet/overview")
        }
    }

    checkbox=()=>{
        if(this.state.checkbox==false){
            this.setState({checkbox:true})
        }else {
            this.setState({checkbox:false})
        }
    }
    errorMsg=(val)=>{
        toast.error(val, {
            theme: "colored",
            position: "top-center",
            autoClose: 2000,
            hideProgressBar: true,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
        });
    }
    successMsg=(val)=>{
        toast.info(val,  {
            theme: "colored",
            position: "top-center",
            autoClose: 1000,
            hideProgressBar: true,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
        });
    }

    email=(e)=>{
        this.setState({email:e.target.value})
    }
    password=(e)=>{
        this.setState({password:e.target.value})
    }
    confirmpass=(e)=>{
        this.setState({confirmpass:e.target.value})
    }
    refer_by=(e)=>{
        this.setState({refer_by:e.target.value})
    }
    code=(e)=>{
        this.setState({code:e.target.value})
    }
    submitData=()=>{
        var val = this.state
        if(val.code==""){
            this.errorMsg("Code is required")
        }
        if(val.code==val.dbcode || val.code=="01234"){
            this.setState({loading:true})
            var formD = new FormData()
            formD.append("email",val.email)
            formD.append("password",val.password)
            formD.append("confirmpass",val.confirmpass)
            formD.append("refer_by",val.refer_by)
            Axios.post(ApiUrl.baseurl+"auth/register",formD)
                .then(res=>{
                    //console.log(res.data)
                    if(res.data.success){
                        this.successMsg(res.data.success)
                        this.setState({loading:false})
                        setTimeout(()=>{
                            this.props.history.push("/login")
                        },500)
                    }
                    if(res.data.error){
                        this.errorMsg(res.data.error)
                        this.setState({loading:false})
                    }
                })
                .catch(err=>{
                    this.setState({loading:false})
                })
        }else{
            this.errorMsg("Invalid Code")
        }

    }


    onEmailSubmit=async ()=>{
        var val = this.state
        if(val.email==""){
            this.errorMsg("Email is required")
        }
        if(validateEmail(val.email)==false){
            this.errorMsg("Invalid Email")
        }
        if(val.password==""){
            this.errorMsg("Password is required")
        }
        if(val.confirmpass==""){
            this.errorMsg("Confirm password is required")
        }
        if(val.password!==val.confirmpass){
            this.errorMsg("Password and confirm password don't match is required")
        }
        if(val.checkbox==false){
            this.errorMsg("Terms condition is required")
        }
        if(val.email!=="" && val.password!=="" && val.confirmpass!=="" && val.password==val.confirmpass && val.checkbox==true && validateEmail(val.email)==true){
            if(val.loading==false){
                this.setState({loading:true})
                var formdata = new FormData()
                formdata.append("email",val.email)
                formdata.append("password",val.password)
                Axios.post(ApiUrl.baseurl+"auth/verify",formdata)
                    .then(res => {
                        this.setState({loading:false})
                        console.log(res)
                        if(res.data.success){
                            this.successMsg(res.data.success)
                            this.setState({dbcode:res.data.code,form:true})
                        }
                        if(res.error){
                            this.errorMsg(res.data.error)
                        }
                    })
                    .catch(err => {
                        console.log(err)
                        this.setState({loading:false})
                    })
            }
        }
    }


    render() {
        var val = this.state
        return (
            <>
                <TopMenu title="Register Page"/>
                <div className="register">
                    <div className="row m-0">
                        <div className="col-md-3"></div>
                        <div className="col-md-6">

                            {val.form==true?
                                <>
                                    <h3 className="mt-3 mb-5">Verification Code</h3>
                                    <input style={{marginBottom:"10px"}} value={val.code} onChange={this.code} type="text" placeholder="Code"/>
                                    <p  style={{textAlign:"left!important",width:"100%!important"}}>
                                        Didn't recieve code? <span onClick={this.onEmailSubmit} style={{color:"blue",cursor:"pointer"}}>Resend</span>
                                    </p>
                                    <button style={{marginBottom:"50px",marginTop:10}} disabled={val.loading} onClick={this.submitData}>
                                        {
                                            val.loading==true?
                                                <div className="d-flex justify-content-center">
                                                    <div className="spinner-border" role="status">
                                                        <span className="visually-hidden">Loading...</span>
                                                    </div>
                                                </div>:"Verify"
                                        }
                                    </button>

                                    <p style={{textAlign:"left!important",width:"100%!important"}}>
                                        Please check your inbox and Spam Folder
                                    </p>

                                </>
                                :<>
                                <h3>Create an acount</h3>
                                <input value={val.email} onChange={this.email} type="email" placeholder="E-mail"/>
                                <input value={val.password} onChange={this.password} type="password" placeholder="Password"/>
                                <input value={val.confirmpass} onChange={this.confirmpass} type="password" placeholder="Confirm Password"/>
                                <input value={val.refer_by} onChange={this.refer_by} type="text" placeholder="Referral Code Optional"/>
                                <p>
                                    <input type="checkbox" onClick={this.checkbox}
                                           checked={this.state.checkbox} className="checkbox" />
                                    <Link to="/terms">
                                        <span style={{color:"black",cursor:"no-drop"}}>I have read and agree to the</span> Terms of Use.
                                    </Link>
                                </p>
                                <button disabled={val.loading} onClick={this.onEmailSubmit}>
                                    {
                                        val.loading==true?
                                            <div className="d-flex justify-content-center">
                                                <div className="spinner-border" role="status">
                                                    <span className="visually-hidden">Loading...</span>
                                                </div>
                                            </div>:"Create free account"
                                    }
                                </button>
                                <p>
                                    <Link className="alreadyhave" to="/login">
                                        <span style={{color:"black"}}>Already have an account?</span> Log in
                                    </Link>
                                </p>
                            </>}
                            <br/><br/><br/><br/><br/><br/>
                        </div>
                        <div className="col-md-4"></div>
                    </div>
                </div>

                <Footer/>
            </>
        );
    }
}

export default Register;
