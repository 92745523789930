import React,{Component,Fragment} from "react";
import TopMenu from "../components/TopMenu";
import Footer from "../components/Footer";
import {Link} from "react-router-dom";

class NotFound extends Component {

    render() {
        return (
            <Fragment>
                <TopMenu title="Not Found 404"/>
                <h1 style={{textAlign:"center",marginTop:"250px"}}>404</h1>
                <p style={{textAlign:"center",marginBottom:"300px"}}>
                    <Link style={{textDecoration:"none"}} to="/">Home Page</Link></p>
                <Footer/>
            </Fragment>
          );
    }
}


export default NotFound;
