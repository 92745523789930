import React, {Component} from 'react';
import TopMenu from "../TopMenu";
import Footer from "../Footer";
import {Link} from "react-router-dom";
import Axios from "axios";
import ApiUrl from "../../AppUrl/ApiUrl";

class Web3Page extends Component {

    constructor() {
        super();
        this.state={
            key:"",address:"",loading:false,
        }
    }


    newKey=()=>{
        this.setState({loading:true,key:""})
        Axios.post(ApiUrl.baseurl+"/key")
            .then(res=>{
                this.setState({key:res.data.key,address:res.data.address,loading:false})
            })
            .catch(err=>{
                this.setState({loading:false})
            })
    }

    render() {
        return (
            <>
                <TopMenu title="Web3 Wallet Bankcoin Cash"/>

                <div className="web3">
                    <h3>BankcoinCashWallet</h3>
                    <h1>
                        The most reputable, friendly,<br/> and secure crypto wallet.
                    </h1>
                    <img src="/peggy.88d038b6.svg"/>
                    <Link disabled={this.state.loading} onClick={this.newKey} to="#">
                        {this.state.loading?"Loading.................":"Create a new wallet"}
                    </Link> or <Link to="/web3/access">Access my wallet</Link>

                    <br/><br/><br/><br/>
                    {
                        this.state.key?
                            <p className="bg-warning text-dark p-3 border-2" style={{textAlign:"left"}}>
                        <span className="text-danger fw-bold">Please Don't share your private key keep safe place,<br/>
                        if you lose your private key . we can't recovery
                        </span><br/><br/>
                                New Private Key : {this.state.key} <br/>
                                Address : {this.state.address}
                                <br/><br/>
                            </p>:""
                    }
                    <br/><br/><br/><br/><br/><br/>

                </div>
                <Footer/>
            </>
        );
    }
}

export default Web3Page;