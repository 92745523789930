import React, {Component} from 'react';
import Deposit from "./Deposit";
import Withdrawal from "./Withdrawal";
import Mining from "./Mining";
import AllHistory from "./AllHistory";
import Wallet from "../../page/Wallet";

class Hisory extends Component {
    constructor() {
        super();
        this.state={
            tab:"all"
        }
    }

    componentDidMount(){
        var token = localStorage.getItem("authtoken")
        if(token){
            this.setState({token:token})
        }else {
            this.props.history.push("/login")
        }
    }

    tab=(val)=>{
        this.setState({tab:val})
    }
    render() {
        return (
            <Wallet>
                <div className="history">
                    <h3>Transaction History</h3>
                    <div className="historycard">
                       <ul>
                           <li className={this.state.tab=="all"?"active":""} onClick={this.tab.bind(this,"all")}>All</li>
                           <li className={this.state.tab=="deposit"?"active":""} onClick={this.tab.bind(this,"deposit")}>Deposit</li>
                           <li className={this.state.tab=="withdrawal"?"active":""} onClick={this.tab.bind(this,"withdrawal")}>Withdrawal</li>
                           <li className={this.state.tab=="mining"?"active":""} onClick={this.tab.bind(this,"mining")}>Mining</li>
                       </ul>
                        {this.state.tab=="all"?<AllHistory/>:""}
                        {this.state.tab=="deposit"?<Deposit/>:""}
                        {this.state.tab=="withdrawal"?<Withdrawal/>:""}
                        {this.state.tab=="mining"?<Mining/>:""}
                    </div>
                </div>
            </Wallet>
        );
    }
}

export default Hisory;