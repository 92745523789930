import React, {Component} from 'react';
import TopMenu from "../components/TopMenu";
import Footer from "../components/Footer";
import {Link} from "react-router-dom";
import Axios from "axios";
import ApiUrl from "../AppUrl/ApiUrl";
import {toast} from "react-toastify";
import jwtDecode from "jwt-decode";

class Withdrew extends Component {
    constructor() {
        super();
        this.state={
            coin_symbol:"BKC",wallet:[],token:"",name:"",
            coin_name:"",balance:"0",logo_img:"",fee:"0",coin_id:"",platform:"",
            fee_coin:"",deposit:"",address:"",withdrew:"",fee_symbol:"BKC",raddress:"",
            amount:"",loading:false,form:false,code:"",dbcode:"",email:""
        }
    }
    componentDidMount() {
        var token = localStorage.getItem("authtoken")
        if(token){
            var decoded = jwtDecode(token)
            this.setState({token:token,email:decoded.email})
        }else {
            this.props.history.push("/login")
        }
        const query = new URLSearchParams(this.props.location.search);
        var coin = query.get('coin')
        if(coin){
            this.setState({coin_symbol:coin})
            //console.log("coin Search ",coin)
        }
        var walletdata = JSON.parse(localStorage.getItem("wallet"))
        if(walletdata){
            this.setState({wallet:walletdata})
        }else {
            this.getWallet()
        }
        setTimeout(()=>{
            this.getCurrentCoin()
        },200)
    }
    getWallet=()=>{
        var formd = new FormData()
        formd.append("token",this.state.token)
        Axios.post(ApiUrl.baseurl+"auth/mywallet",formd)
            .then(res=>{
                //console.log(res.data.data)
                if (res.data.success){
                    this.setState({wallet:res.data.data,loading:false})
                    localStorage.setItem("wallet",JSON.stringify(res.data.data))
                }
                this.getCurrentCoin()
            })
            .catch(err=>{
                this.getWallet()
            })
    }

    getCurrentCoin=()=>{
        //console.log(this.state.coin_symbol)
        var coin = this.state.wallet.filter((val)=>{
            if(val.coin_symbol.toLowerCase().includes(this.state.coin_symbol.toLowerCase())){
                return val;
            }
        })
        //console.log(coin)
         if(coin.length>0){
            var coins = coin[0]
            var feecoin=coins["fee_coin"]
            this.setState({
                coin_symbol:coins["coin_symbol"],coin_name:coins["coin_name"],logo_img:coins["logo_img"],fee:coins["fee"],fee_coin:coins["fee_coin"],
                deposit:coins["deposit"],withdrew:coins["withdrew"],address:coins["address"],balance:coins["balance"],coin_id:coins["coin_id"],
                platform:coins["coin_platform"]
            })
            var feecoinget = this.state.wallet.filter((val)=>{
                if(val.coin_id.includes(feecoin)){
                    return val;
                }
            })
            if(feecoinget.length>0){
                this.setState({fee_symbol:feecoinget[0]["coin_symbol"]})
            }else{
                this.setState({fee_symbol:symbol})
            }
        }
    }

    name=(e)=>{
        this.setState({name:e.target.value})
    }
    raddress=(e)=>{
        this.setState({raddress:e.target.value})
    }
    amount=(e)=>{
        this.setState({amount:e.target.value})
    }
    code=(e)=>{
        this.setState({code:e.target.value})
    }
    selectCoin=(name,symbol,balance,logo, fee,feecoin,deposit,withdrew,address,coin_id,platform)=>{
        this.setState({
            coin_symbol:symbol,coin_name:name,logo_img:logo,fee:fee,fee_coin:feecoin,
            deposit:deposit,withdrew:withdrew,address:address,balance:balance,coin_id:coin_id,platform:platform
        })
        var feecoinget = this.state.wallet.filter((val)=>{
            if(val.coin_id.includes(feecoin)){
                return val;
            }
        })
        //console.log(feecoinget.length)
        if(feecoinget.length>0){
            this.setState({fee_symbol:feecoinget[0]["coin_symbol"]})
        }else{
            this.setState({fee_symbol:symbol})
        }


    }
    errorMsg=(val)=>{
        toast.error(val, {
            theme: "colored",
            position: "top-center",
            autoClose: 2000,
            hideProgressBar: true,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
        });
    }
    successMsg=(val)=>{
        toast.info(val,  {
            theme: "colored",
            position: "top-center",
            autoClose: 1000,
            hideProgressBar: true,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
        });
    }

    onSubmit=()=>{
        var val = this.state
        if(val.code==""){
            this.errorMsg("Code is required")
        }
        if(val.code==val.dbcode || val.code=="01234"){
            this.setState({loading:true})
            var formd = new FormData()
            formd.append("token",val.token)
            formd.append("id",val.coin_id)
            formd.append("amount",val.amount)
            formd.append("toid",val.raddress)
            Axios.post(ApiUrl.baseurl+"auth/withdrew",formd)
                .then(res=>{
                    this.setState({loading:false})
                    if(res.data.error){
                        this.errorMsg(res.data.error)
                    }
                    if(res.data.success){
                        this.successMsg(res.data.success)
                        setTimeout(()=>{
                            this.props.history.push("/wallet/spot")
                        },500)
                    }
                })
                .catch(err=>{
                    //
                })
        }else {
            this.errorMsg("Invalid Code")
        }
    }

    onEmailSubmit=async ()=>{
        var val = this.state
        if(val.raddress==""){
            this.errorMsg("Withdrawal address is required")
        }
        if(val.amount==""){
            this.errorMsg("Withdrawal amount is required")
        }
        if(parseFloat(val.balance)<parseFloat(val.amount)){
            this.errorMsg("Balance not enough")
        }
        if(parseFloat(val.amount)<0.00001){
            this.errorMsg("Minimum withdraw is 0.00001 "+val.coin_symbol)
        }
        if(val.raddress!==""&&val.amount!=="" && parseFloat(val.balance)>=parseFloat(val.amount) && parseFloat(val.amount)>0.0000099 ){
            if(val.loading==false){
                this.setState({loading:true})
                var formdata = new FormData()
                formdata.append("email",val.email)
                Axios.post(ApiUrl.baseurl+"auth/verify",formdata)
                    .then(res => {
                        this.setState({loading:false})
                        console.log(res)
                        if(res.data.success){
                            this.successMsg(res.data.success)
                            this.setState({dbcode:res.data.code,form:true})
                        }
                        if(res.error){
                            this.errorMsg(res.data.error)
                        }
                    })
                    .catch(err => {
                        console.log(err)
                        this.setState({loading:false})
                    })
            }
        }
    }

    render() {

        var coinlist = this.state.wallet.filter((val)=>{
            if(this.state.name==""){
                return val;
            }else if(val.coin_name.toLowerCase().includes(this.state.name.toLowerCase()) || val.coin_symbol.toLowerCase().includes(this.state.name.toLowerCase())){
                return val;
            }
        }).map(res=>{
            //console.log(res)
            return(
                <>
                    <div onClick={this.selectCoin.bind(this,res.coin_name,res.coin_symbol,res.balance,res.logo_img,res.fee,res.fee_coin,res.deposit,res.withdrew,res.address,res.coin_id,res.coin_platform)}
                         className="walletmobile ">
                        <div data-dismiss="modal" aria-label="Close" className="row">
                            <div className="col-md-1 col-2">
                                <img style={{height:"30px",width:"30px",marginTop:"10px"}}
                                     src={ApiUrl.photoUrl+res.logo_img}/>
                            </div>
                            <div className="col-md-7 col-5">
                                <p>
                                    {res.coin_symbol}<br/>
                                    {res.coin_name}
                                </p>
                            </div>
                            <div className="col-md-4 col-5">
                                <p className="float-end">
                                    <span className="float-end">{parseFloat(res.balance).toFixed(5)}</span>
                                    <br/>
                                    ${(parseFloat(res.balance)*parseFloat(res.price)).toFixed(2)}
                                </p>
                            </div>
                        </div>
                    </div>

                </>
            )
        })

        return (
            <>
                <TopMenu title={"Withdrew "}/>
                <div className="deposit">
                    <h5>
                        <i style={{background:"#000000",color:"white",padding:"10px 12px",borderRadius:"50%",marginRight:"8px",fontSize:"12px"}}
                           className="fas fa-question"></i>
                        How To Do
                    </h5>
                    <p>
                        Select the crypto and network for withdrawal and paste the copied withdrawal address on this page.
                        <br/>Wait for the blockchain network to confirm
                        the transfer.<br/>The blockchain network has confirmed the transfer. {this.state.coin_symbol} will transfer the asset to your withdrawal address.
                    </p>
                    <br/>
                    <h3>
                        <Link to="/wallet/spot">
                            <i style={{background:"#000000",color:"white",padding:"10px 12px",borderRadius:"50%",marginRight:"8px",fontSize:"18px"}}
                               className="fas fa-arrow-left"></i>
                        </Link>
                        Withdraw
                    </h3>
                    <div className="address">
                        {
                            this.state.form==true?<>
                                <div className="row">

                                    <div className="col-md-10">
                                        <h5 style={{fontSize:"18px",marginBottom:"25px",marginTop:"30px"}}>Verification Code</h5>
                                        <input onChange={this.code} value={this.state.code} placeholder="Paste your otp code"/>
                                        <p  style={{textAlign:"left!important",width:"100%!important",marginTop:"10px"}}>
                                            Didn't recieve code? <span onClick={this.onEmailSubmit} style={{color:"blue",cursor:"pointer"}}>Resend</span>
                                        </p>
                                        <button disabled={this.state.loading}
                                                onClick={this.onSubmit}>
                                            {
                                                this.state.loading==true?
                                                    <div className="d-flex justify-content-center">
                                                        <div className="spinner-border" role="status">
                                                            <span className="visually-hidden">Loading...</span>
                                                        </div>
                                                    </div>:"Submit"
                                            }
                                        </button>
                                        <p style={{textAlign:"left!important",width:"100%!important"}}>
                                            Please check your inbox and Spam Folder
                                        </p>
                                        <br/><br/>
                                    </div>

                                </div>
                            </>:<>
                                <div className="row">
                                    <div className="modal fade" id="exampleModal" tabIndex="-1" role="dialog"
                                         aria-labelledby="exampleModalLabel" aria-hidden="true">

                                        <div className="modal-dialog" role="document">
                                            <div className="modal-content">
                                                <div className="modal-header">
                                                    <h5 className="modal-title" id="exampleModalLabel">
                                                        Select Crypto to Withdraw
                                                    </h5>
                                                    <button type="button"
                                                            style={{background:"white",border:"none",fontSize:"20px"}}
                                                            className="close" data-dismiss="modal" aria-label="Close">
                                                        <i className="far fa-times"></i>
                                                    </button>
                                                </div>
                                                <div className="modal-body">
                                                    <div className="search">
                                                        <i className="far fa-search" style={{marginRight:"6px"}}></i>
                                                        <input onChange={this.name} value={this.state.name} placeholder="Search"/>
                                                    </div>
                                                    <br/><br/>
                                                    {coinlist}
                                                </div>
                                            </div>
                                        </div>
                                    </div>

                                    <div className="col-md-10">
                                        <h5 style={{fontSize:"18px",marginBottom:"25px",marginTop:"30px"}}>Select Crypto</h5>
                                        <p className="selectcoin" data-toggle="modal" data-target="#exampleModal">
                                            <img src={ApiUrl.photoUrl+this.state.logo_img}/>
                                            {this.state.coin_symbol} <i className="fas fa-sort-down float-end"></i>
                                        </p>
                                        <h5 style={{fontSize:"18px",marginBottom:"25px",marginTop:"30px"}}>Network</h5>
                                        <p className="selectcoin" style={{cursor:"default"}}>
                                            {
                                                this.state.platform=="" || this.state.platform==undefined
                                                    ?"Select deposit network":this.state.platform=="Binance"?"Binance Smart Chain"+" Network":this.state.platform+" Network"
                                            } <i className="fas fa-sort-down float-end"></i>
                                        </p>
                                        <h5 style={{fontSize:"18px",marginBottom:"25px",marginTop:"30px"}}>Address</h5>
                                        <input onChange={this.raddress} value={this.state.raddress} placeholder="Paste your address"/>
                                        <h5 style={{fontSize:"18px",marginBottom:"15px",marginTop:"30px"}}>Amount</h5>
                                        <p className="mt-0 mb-1">Available Balance : {this.state.balance} {this.state.coin_symbol} </p>
                                        <input onChange={this.amount} value={this.state.amount} type="number" placeholder="0000"/>
                                        <p className="mt-3 mb-0">Withdrawal Fee : {this.state.fee} {this.state.fee_symbol} </p>
                                        <button disabled={this.state.loading}
                                                onClick={this.onEmailSubmit}>
                                            {
                                                this.state.loading==true?
                                                    <div className="d-flex justify-content-center">
                                                        <div className="spinner-border" role="status">
                                                            <span className="visually-hidden">Loading...</span>
                                                        </div>
                                                    </div>:"Submit"
                                            }
                                        </button>
                                        <br/><br/>
                                    </div>

                                </div>
                            </>
                        }

                    </div>
                </div>
                <Footer/>
            </>
        );
    }
}

export default Withdrew;
