import React, {Component} from 'react';
import TopMenu from "../components/TopMenu";
import {Link} from "react-router-dom";
import {toast} from "react-toastify";
import Axios from "axios";
import ApiUrl from "../AppUrl/ApiUrl";
import Footer from "../components/Footer";

function validateEmail(email) {
    var re = /\S+@\S+\.\S+/;
    return re.test(email);
}

class ForgotPassword extends Component {
    constructor() {
        super();
        this.state={
            email:"",loading:false
        }
    }
    componentDidMount() {
        var token = localStorage.getItem("authtoken")
        if(token){
            this.props.history.push("/wallet/overview")
        }
    }

    email=(e)=>{
        this.setState({email:e.target.value})
    }

    errorMsg=(val)=>{
        toast.error(val, {
            theme: "colored",
            position: "top-center",
            autoClose: 2000,
            hideProgressBar: true,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
        });
    }
    successMsg=(val)=>{
        toast.info(val,  {
            theme: "colored",
            position: "top-center",
            autoClose: 1000,
            hideProgressBar: true,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
        });
    }

    submitData=()=>{
        var val = this.state
        if(val.email==""){
            this.errorMsg("Email is required")
        }
        if(validateEmail(val.email)==false){
            this.errorMsg("Invalid Email")
        }
        if(val.email!=="" && validateEmail(val.email)==true ){
            this.setState({loading:true})
            var formD = new FormData()
            formD.append("email",val.email)
            Axios.post(ApiUrl.baseurl+"auth/forgot",formD)
                .then(res=>{
                    //console.log(res.data)
                    if(res.data.success){
                        this.successMsg(res.data.success)
                        this.setState({loading:false})
                    }
                    if(res.data.error){
                        this.errorMsg(res.data.error)
                        this.setState({loading:false})
                    }
                })
                .catch(err=>{
                    this.setState({loading:false})
                })
        }

    }

    render() {
        var val =this.state
        return (
            <>
                <TopMenu title="Forgot Password"/>
                <div className="register">
                    <div className="row m-0">
                        <div className="col-md-3"></div>
                        <div className="col-md-6">
                            <h3 className="mt-3 ">Forgot password</h3><br/>
                            <input value={val.email} onChange={this.email} type="email" placeholder="E-mail"/>
                            <button style={{marginTop:"10px"}} disabled={val.loading} onClick={this.submitData}>
                                {
                                    val.loading==true?
                                        <div className="d-flex justify-content-center">
                                            <div className="spinner-border" role="status">
                                                <span className="visually-hidden">Loading...</span>
                                            </div>
                                        </div>:"Submit"
                                }
                            </button>
                            <p className="mb-5">
                                <br/><br/><br/><br/>
                                <Link className="" to="/register">
                                    <span style={{color:"black"}}>Don't have an account? </span> Sign Up
                                </Link>
                                <Link className="float-end" to="/login">
                                    Login
                                </Link>
                            </p>
                            <br/><br/><br/><br/><br/><br/><br/>
                        </div>
                        <div className="col-md-4"></div>
                    </div>

                </div>
                <Footer/>
            </>
        );
    }
}

export default ForgotPassword;
