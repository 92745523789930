import React, {Component} from 'react';
import TopMenu from "../components/TopMenu";
import Footer from "../components/Footer";

class TermsConditions extends Component {
    render() {
        return (
            <>
                <TopMenu title="Terms & Conditions"/>
                <div className="terms">
                    <h1>Terms & Conditions</h1>
                    <br/>
                    <p>
                        This User Agreement is concluded between you ("You" or "User") and Bankcoin Cash Trading
                        Platform ("we", "us", "our", "ours", "Bankcoin Cash", or, the "Platform"). By using, accessing, or attempting to use
                        or access Bankcoin Cash website (https://bankcoincash.org), Apps and any other services provided by Bankcoin Cash or
                        affiliated companies thereof (the "Services"), it will be deemed that you have read and agreed to all
                        the terms and conditions specified in this User Agreement and Privacy Policy ("Agreement"), as well as
                        the amendments and updates which we may make thereto, and any other agreements, terms, conditions,
                        guidelines, rules, or written instructions, as published by Bankcoin Cash on its website, Apps, or other Services and
                        amendments and changes thereto, from time to time (altogether as the 'Legal Documents'). If you do not agree or
                        otherwise understand the nature of this Agreement and the Legal Documents,
                        do not access Bankcoin Cash or use the corresponding Bankcoin Cash Services.
                        <br/><br/>
                        BY MAKING USE OF Bankcoin Cash SERVICES, YOU ACKNOWLEDGE AND AGREE THAT: <br/>(1)
                        YOU ARE AWARE OF THE RISKS ASSOCIATED WITH TRANSACTIONS OF DIGITAL ASSETS AND THEIR DERIVATIVES;<br/> (2)
                        YOU SHALL ASSUME ALL RISKS RELATED TO THE USE OF Bankcoin Cash SERVICES AND TRANSACTIONS OF DIGITAL ASSETS AND
                        THEIR DERIVATIVES; AND <br/>(3) Bankcoin Cash SHALL NOT BE LIABLE FOR ANY SUCH RISKS OR ADVERSE OUTCOMES.
                    </p>
                    <br/><br/><br/><br/><br/><br/>
                </div>

                <Footer/>
            </>
        );
    }
}

export default TermsConditions;
