import React,{Component,Fragment} from "react";
import Footer from "../components/Footer";
import TopMenu from "../components/TopMenu";
import Carousel from 'better-react-carousel'
import {Link} from "react-router-dom";
import jwtDecode from "jwt-decode";
import Axios from "axios";
import ApiUrl from "../AppUrl/ApiUrl";

class Home extends Component {

    constructor() {
        super();
        this.state={
            token:"",blog:[],coin:[]
        }
    }

    componentDidMount() {
        const query = new URLSearchParams(this.props.location.search);
        var ref = query.get('ref')
        if(ref){
            sessionStorage.setItem("ref",ref)
        }
        var token = localStorage.getItem("authtoken")
        if(token){
            this.setState({token:token})
        }
        var blog = JSON.parse(localStorage.getItem("blog"))
        if(blog){
            this.setState({blog:blog})
        }
        var coin = JSON.parse(localStorage.getItem("coin"))
        if(coin){
            this.setState({coin:coin})
        }
        Axios.get("https://api.coinpaprika.com/v1/tickers/btc-bitcoin")
            .then(res=>{
                //console.log(res.data.quotes["USD"]["price"])
                localStorage.setItem("btc",res.data.quotes["USD"]["price"])
            })
            .catch(err=>{
                //console.log(err)
            })
        this.getBlog()
        this.getCoin()
        this.priceUpdate()
    }

    getBlog=()=>{
        Axios.get(ApiUrl.baseurl+"all-blog")
            .then(res=>{
                console.log(res.data)
                if(res.data.error){
                    console.log("err")
                }else{
                    if(res.data.length>0){
                        localStorage.setItem("blog",JSON.stringify(res.data))
                        this.setState({blog:res.data})
                    }
                }
            })
            .catch(err=>{
                //console.log(err)
                //this.getBlog()
            })
    }
    getCoin=()=>{
        Axios.get(ApiUrl.baseurl+"all-coin")
            .then(res=>{
                console.log(res.data)
                if(res.data.error){
                    console.log("err")
                }else{
                    if(res.data.length>0){
                        localStorage.setItem("coin",JSON.stringify(res.data))
                        this.setState({coin:res.data})
                    }
                }
            })
            .catch(err=>{
                //console.log(err)
                //this.getCoin()
            })
    }
    priceUpdate=()=>{
        Axios.get(ApiUrl.baseurl+"coin-price")
            .then(res=>{
                console.log(res.data)
            })
            .catch(err=>{
                //console.log(err)
            })
    }

    render() {

        var val = this.state

        var sliderimg = val.blog.map(res=>{
            return(
                <Carousel.Item>
                    <Link to={"/news-blog/"+res.id}>
                        <img width="100%" height="150px" src={ApiUrl.photoUrl+res.img} />
                    </Link>
                </Carousel.Item>
            )
        })

        var coinlist = val.coin.map(res=>{
            return(
                <>
                    <tr>
                        <td className="crypto-name">
                            <div
                                className="coin-info d-flex " style={{textAlign:"left",
                                paddingLeft:"20px"}}>
                                <div className="coin-logo me-1">
                                    <img src={ApiUrl.photoUrl+res.logo_img}
                                         style={{height:"30px",width:"30px",borderRadius:"50%"}}
                                         alt={res.coin_symbol}/>
                                </div>
                                <h2 className="coin-name text-left fw-bold fs-5 m-0 mt-1">{res.coin_symbol}</h2>
                            </div>
                        </td>
                        <td>
                            <div className="last-price">
                                <p className="coin-price fw-bold p-0 m-0" style={{paddingLeft:"50px"}}>{parseFloat(res.price).toFixed(2)} USD</p>
                            </div>
                        </td>
                        <td>
                            <div className="chage-24 text-center">
                                <p className={parseFloat(res.day_change)>0?"change fw-bold text-primary":
                                    "change fw-bold text-danger"}>{parseFloat(res.day_change)>0?"+ ":" "}{ res.day_change}</p>
                            </div>
                        </td>
                        <td className="popular-chart">
                            <div className="graph" style={{textAlign:"left",
                                paddingRight:"20px"}}>
                                <img src="/chart.jpg" alt=""/>
                            </div>
                        </td>

                    </tr>

                </>
            )
        })

        return (
            <Fragment>
                <TopMenu title="Bankcoin Cash Exchange Platform"/>
                <section id="hero-section" className="py-5">
                    <div className="container">
                        <div className="row py-5  align-items-center">
                            <div className="col-md-6 ">
                                <h1 className="text-white fw-bold ">Start Your Bankcoin Cash
                                    <br/> Mining and Airdrop
                                    </h1>
                                <h6 className="fw-bold text-white mt-3 mb-3">Don't miss out on this limited-timer offer!</h6>
                                <div className="action ">
                                    {
                                        val.token?
                                          <>
                                              <Link to="/mining" style={{border:"1px solid white"}}
                                                    className="btn koco-primary bg-white border-1 text-dark me-3 shadow-none">Mining</Link>
                                              <Link to="/wallet" className="btn koco-outline shadow-none">My Wallet</Link>
                                          </>:
                                            <>
                                                <Link to="/register"  style={{border:"1px solid white"}}
                                                      className="btn koco-primary bg-white border-0 text-dark me-3 shadow-none">Sign Up</Link>
                                                <Link to="/login" className="btn koco-outline shadow-none">Get Reward</Link>
                                            </>
                                    }
                                </div>
                                <div className="row mt-4 d-none">
                                    <div className="col-2">
                                        <h4 className="text-white fs-6 text-left">100k <br/> Coin</h4>
                                    </div>
                                    <div className="col-4 text-left">
                                        <h4 className="text-white fs-6 text-left">100k <br/> Global Investors</h4>
                                    </div>
                                    <div className="col-5 ">
                                        <h4 className="text-white fs-6 text-left">100k <br/> 24h trading text-center
                                            Volume</h4>
                                    </div>
                                </div>
                            </div>
                            <div className="col-md-6 ">
                                <div className="hero-img">
                                    <img src="/trusted.png" alt="Hero Image" className="img-fluid"/>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>

                <div className="bg-white" style={{height:"100%",width:"100%"}}>
                    <section id="banner-slider bg-white" className="py-5">
                        <div className="container">
                            <div className="row">
                                <div className="col-md-12 mb-2">
                                    <p>
                                        <Link to="/news-blog"
                                              style={{marginBottom:"5px",
                                                  textDecoration:"none",
                                                  float:"right",
                                                  paddingRight:"20px"
                                              }}>
                                            View All <i className="fas fa-arrow-right"></i></Link>
                                    </p>

                                </div>
                                <div className="col-md-12">
                                    <Carousel  dotColorActive={"#100e0e"} dotColorInactive={"#ccc"}
                                               cols={4} rows={1} gap={15} loop={true}
                                               scrollSnap={true} showDots={true}>
                                        {sliderimg}

                                    </Carousel>
                                </div>
                            </div>
                        </div>
                    </section>
                    <section id="crypto-market" className="py-5 " style={{background:"#fafafa"}}>
                        <div className="container">

                            <div className="row">
                                <div className="col-md-12">
                                    <div className="data-table overflow-auto">
                                        <br/>
                                        <div className="tab-content bg-white overflow-auto" style={{borderRadius:"15px"}}>
                                            <div className="tab-pane active" id="home" role="tabpanel"
                                                 aria-labelledby="home-tab">
                                                <div className="popular mt-4">
                                                    <table className="table table-bordered border-primary align-middle">
                                                        <thead className="border">
                                                        <tr className="text-center text-primary table-head">
                                                            <th scope="col " style={{textAlign:"left",paddingLeft:"20px"}}>Crypto Name</th>
                                                            <th scope="col" >Last Price</th>
                                                            <th scope="col">Change 24h</th>
                                                            <th scope="col">Chart</th>
                                                        </tr>
                                                        </thead>
                                                        <tbody>
                                                        {coinlist}
                                                        </tbody>
                                                    </table>


                                                </div>
                                            </div>
                                        </div>

                                    </div>
                                </div>
                            </div>
                        </div>
                    </section>

                    <section id="koco-coins-ervices" className="bg-secondary pt-3">
                        <div className="container">
                            <div className="row">
                                <div className="col-12 ">
                                    <h2 className="text-center pt-5 pb-6 mb-5 fw-bold text-light">Bankcoin Cash Products &
                                        Services</h2>
                                </div>
                            </div>
                            <div className="row pb-5">
                                <div className="col-sm-12 col-md-6 col-lg-6 col-xl-6 mb-3">
                                    <div className="service" style={{background:"#3650AE"}}
                                        className=" d-md-flex d-sm-block d-lg-flex rounded-10 px-5 py-4 ">
                                        <div className="info ">
                                            <h2 className=" fw-bold rounded-10 text-white">Web3 Wallet</h2>
                                            <p className="text-white fw-bold p-0">Bankcoin Cash Provide Web3 Wallet for Bankcoin Cash Blockchain Coin.
                                                Our Web3 Wallet is Decentralized Databaseless Secure Wallet
                                            </p>
                                        </div>
                                        <div className="feature_img  ">
                                            <img style={{width:"170px"}} src="/spot.png" alt=""/>
                                        </div>
                                    </div>
                                </div>
                                <div className="col-sm-12 col-md-6 col-lg-6 col-xl-6 mb-3">
                                    <div className="service" style={{background:"#3650AE"}}
                                        className="d-md-flex d-sm-block d-lg-flex  rounded-10  px-5 py-4">
                                        <div className="info">
                                            <h2 className=" fw-bold rounded-10 text-white">Own Blockchain </h2>
                                            <p className="text-white fw-bold p-0">Bankcoin Cash is a Ethereum base Technology Self Private Blockchain Coin.
                                                This is not a Token
                                            </p>
                                        </div>
                                        <div className="feature_img">
                                            <img src="/Roket.png" alt=""/>
                                        </div>
                                    </div>
                                </div>
                                <div className="col-sm-12 col-md-6 col-lg-6 col-xl-6 mb-3">
                                    <div className="service" style={{background:"#3650AE"}}
                                        className=" d-md-flex d-sm-block d-lg-flex  rounded-10  px-5 py-4">
                                        <div className="info">
                                            <h2 className="text-white fw-bold rounded-10">Mining</h2>
                                            <p className="text-white fw-bold p-0">
                                                We provide Bankcoin Cash Mining Every 24 Hours 5 BKC.
                                                Without Hardware Resourses Just 1 Click Miming Start
                                            </p>
                                        </div>
                                        <div className="feature_img">
                                            <img src="/funding.png" alt=""/>
                                        </div>
                                    </div>
                                </div>
                                <div className="col-sm-12 col-md-6 col-lg-6  col-xl-6 mb-3">
                                    <div className="service" style={{background:"#3650AE"}}
                                        className=" d-md-flex d-sm-block d-lg-flex  rounded-10  px-5 py-4">
                                        <div className="info">
                                            <h2 className="text-white fw-bold rounded-10">Supply </h2>
                                            <p className="text-white fw-bold p-0">
                                                Bankcoin Cash is a small Coin Supply 800000000 BKC. This is a not big Amount and CSupply is 100000 BKC
                                            </p>
                                        </div>
                                        <div className="feature_img">
                                            <img  src="/element.png" alt=""/>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </section>
                    <section className="why-choose-koco ">
                        <div className="container ">
                            <div className="row mb-5">
                                <div className="col-12">
                                    <h1 className="text-center text-primary mt-5 mb-2 fw-bold text-light">Why Choose Bankcoin Cash
                                         </h1>
                                </div>
                            </div>
                            <div className="row mb-5">
                                <div className="col-sm-12 col-md-4 col-lg-4  mb-3">
                                    <div className="features  p-4  mb-5" style={{background:"#3650AE",borderRadius:"30px"}}>
                                        <div className="text-center">
                                            <img src="/Secure.png" alt=""/>
                                            <h4 className="text-center text-white fw-bold">Bankcoin Cash Mining</h4>
                                            <p className="text-center text-white fw-bold">We provide Bankcoin Cash Mining Every 24 Hours 5 BKC.
                                                 you can transfer Mining to main wallet for withdraw anytime
                                            </p>
                                        </div>
                                    </div>
                                    <div className="features p-4 "  style={{background:"#3650AE",borderRadius:"30px"}}>
                                        <div className="text-center">
                                            <img src="/06.png" alt=""/>
                                            <h4 className="text-center text-white fw-bold">Bankcoin Cash NFT</h4>
                                            <p className="text-center text-white fw-bold">Bankcoin Cash has strong Nft market.
                                                Can keep Nft safe in your account.
                                                And you can sell and buy.  We use very powerful technology for Nft.  which we use to protect your assets.
                                            </p>
                                        </div>
                                    </div>
                                </div>
                                <div className="col-sm-12 col-md-4 col-lg-4 p-4 ">
                                    <div className="features middle-item   py-sm-0"  style={{background:"#3650AE",borderRadius:"30px"}}>
                                        <div className="text-center p-4">
                                            <img src="/03.png" alt=""/>
                                            <h4 className="text-center text-white fw-bold">Trusted Platform</h4>
                                            <p className="text-center text-white fw-bold">Any attack and risk control we can do very quickly. Where our team is always aware
                                            </p>
                                        </div>
                                    </div>
                                </div>
                                <div className="col-sm-12 col-md-4 col-lg-4  mb-3">
                                    <div className="features  p-4  mb-5"  style={{background:"#3650AE",borderRadius:"30px"}}>
                                        <div className="text-center">
                                            <img src="/stron.png" alt=""/>
                                            <h4 className="text-center text-white fw-bold">Strong Account
                                                Security</h4>
                                            <p className="text-center text-white fw-bold">We maintain strict security and round-the-clock
                                                monitoring to keep your assets safe.
                                                <br/><br/>
                                            </p>
                                        </div>
                                    </div>
                                    <div className="features  p-4"  style={{background:"#3650AE",borderRadius:"30px"}}>
                                        <div className="text-center">
                                            <img src="/07.png" alt=""/>
                                            <h4 className="text-center text-white fw-bold">Bankcoin Cash Blockchain</h4>
                                            <p className="text-center text-white fw-bold">We will turn Bankcoin Cash Blockchain into a powerful Coin.  Bankcoin Cash will be a powerful coin.
                                                Which will always run upwards
                                                <br/><br/>
                                            </p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </section>

                    <section className="star-mining bg-secondary ">
                        <div className="container">
                            <div className="row">
                                <div className="col-md-6 align-self-center">
                                    <div className="mining-img overflow-hidden">
                                        <img width="100%" src="/bk.png" alt=""/>
                                    </div>
                                </div>
                                <div className="col-md-6">
                                    <div className="start-earning mb-5">
                                        <h4 className=" fw-bold pt-5 pb-4" style={{color:"#3650AE"}}>Start Earning Bankcoin Cash Mining Here</h4>
                                        <span className="" style={{color:"#3650AE"}}>Don't miss out on this limited-time offer!</span>
                                        <h6 className="fw-bold pb-3 mt-3" style={{color:"#3650AE"}}>View more benefits</h6>
                                        <div className="row">
                                            <div className="col-md-6 col-12 text-center">
                                                <Link to="/mining">
                                                    <button style={{background:"#3650AE",border:"0px",
                                                        color:"white", padding:"10px 40px",marginTop:"60px"}} className="w-100 mb-3 text-center shadow-none">Start Mining
                                                    </button>
                                                </Link>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </section>

                    <section className="faq  bg-white" style={{paddingBottom:"50px",paddingTop:"50px"}}>
                        <div className="container">
                            <div className="row align-items-center">
                                <div className="col-md-6 overflow-hidden">
                                    <div className="faq-icon overflow-hidden">
                                        <img width="100%" height="100%" src="/faq.png" alt=""/>
                                    </div>
                                </div>
                                <div className="col-md-6">
                                    <div className="faq">
                                        <h2 className="fw-bold fs-2 text-primary mb-3">FAQ</h2>
                                        <div className="faq-accordion">
                                            <div className="accordion" id="accordionExample">
                                                <div className="accordion-item mb-3">
                                                    <h2 className="accordion-header" id="headingOne">
                                                        <button className="accordion-button fw-bold shadow-none text-dark" type="button"
                                                                data-bs-toggle="collapse" data-bs-target="#collapseOne"
                                                                aria-expanded="true"
                                                                aria-controls="collapseOne">What is Bankcoin Cash?
                                                        </button>
                                                    </h2>
                                                    <div id="collapseOne" className="accordion-collapse collapse "
                                                         aria-labelledby="headingOne" data-bs-parent="#accordionExample">
                                                        <div className="accordion-body">
                                                            Bankcoin Cash is a digital currency own Blockchain capable of very fast transactions.
                                                            Users can send and receive BKC directly without intermediaries,
                                                            making it a fast and low-cost way to transfer value.
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="accordion-item mb-3">
                                                    <h2 className="accordion-header" id="headingTwo">
                                                        <button className="accordion-button fw-bold shadow-none text-dark" type="button"
                                                                data-bs-toggle="collapse" data-bs-target="#collapseTwo"
                                                                aria-expanded="true"
                                                                aria-controls="collapseTwo">Is Bankcoin Cash a safe
                                                            cryptocurrency?
                                                        </button>
                                                    </h2>
                                                    <div id="collapseTwo" className="accordion-collapse collapse"
                                                         aria-labelledby="headingTwo" data-bs-parent="#accordionExample">
                                                        <div className="accordion-body">
                                                            Bankcoin Cash is committed to providing a safe and reliable trading platform and uses the world's most sophisticated security technology,
                                                            including a dedicated security team that works continuously to keep your assets and accounts safe, and Bankcoin Cash provides the best service to every user.
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="accordion-item mb-3">
                                                    <h2 className="accordion-header" id="headingThree">
                                                        <button className="accordion-button fw-bold shadow-none text-dark" type="button"
                                                                data-bs-toggle="collapse" data-bs-target="#collapseThree"
                                                                aria-expanded="true"
                                                                aria-controls="collapseThree">What is the lowest amount I can start Withdraw on Bankcoin Cash?
                                                        </button>
                                                    </h2>
                                                    <div id="collapseThree" className="accordion-collapse collapse"
                                                         aria-labelledby="headingThree" data-bs-parent="#accordionExample">
                                                        <div className="accordion-body">
                                                            You can start Withdraw Minimum 0.1 BKC anytime any where Instantly
                                                        </div>
                                                    </div>
                                                </div>

                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </section>
                </div>

                <Footer/>
            </Fragment>
          );
    }
}


export default Home;
