import React, {Component} from 'react';
import TopMenu from "../components/TopMenu";
import Footer from "../components/Footer";
import Axios from "axios";
import ApiUrl from "../AppUrl/ApiUrl";
import ReactHtmlParser from "react-html-parser";
import loadinggif from"../images/loading.gif"
import moment from "moment";

class BlogSingle extends Component {
    constructor({match}) {
        super();
        this.state={
            id:match.params.id,title:"",img:"",des:"",created_at:"",loading:true
        }
    }

    componentDidMount() {
        Axios.get(ApiUrl.baseurl+"blog/"+this.state.id)
            .then(res=>{
                if(res.data.error){
                    console.log("err")
                }else{
                    console.log(res.data)
                    if(res.data.length>0){
                        var val = res.data[0]
                        this.setState({title:val["title"],des:val["des"],img:val["img"],created_at:val["created_at"],
                            loading:false})
                    }
                this.setState({loading:false})
                }
            })
            .catch(err=>{
                //console.log(err)
            })
    }


    render() {
        return (
            <>
                <TopMenu title={this.state.title}/>
                {
                    this.state.loading==true?
                        <div style={{width:"100%",height:"100%",background:"white"}}>
                            <img style={{height:"250px",width:"100%",paddingTop:"50px"}} src={loadinggif}/>
                            <img style={{height:"250px",width:"100%"}} src={loadinggif}/>
                            <img style={{height:"250px",width:"100%",paddingBottom:"50px"}} src={loadinggif}/>
                        </div>:
                        <div className="blogsingle">
                            <h4>{this.state.title}</h4>
                            <p>{moment(this.state.created_at).format(("YYYY-MM-DD HH:mm:ss"))}</p>
                            <img src={ApiUrl.photoUrl+this.state.img}/>
                            <p className="reactquilldes">
                                {ReactHtmlParser(this.state.des)}
                            </p>
                        </div>
                }
                <Footer/>
            </>
        );
    }
}

export default BlogSingle;
