import React, {Component} from 'react';
import TopMenu from "../components/TopMenu";
import Footer from "../components/Footer";
import {toast} from "react-toastify";
import jwtDecode from "jwt-decode";
import Axios from "axios";
import ApiUrl from "../AppUrl/ApiUrl";

class Convert extends Component {

    constructor() {
        super();
        this.state={
            token:"",amount:"0",type:"coin", loading:false,
        }
    }

    componentDidMount() {
        var token = localStorage.getItem("authtoken")
        if(token){
            var decoded = jwtDecode(token)
            this.setState({token:token,uid:decoded.uid})
        }else {
            this.props.history.push("/login")
        }

    }

    amount=(e)=>{
        this.setState({amount:e.target.value})
    }
    type=(e)=>{
        this.setState({type:e.target.value})
    }

    errorMsg=(val)=>{
        toast.error(val, {
            theme: "colored",
            position: "top-center",
            autoClose: 2000,
            hideProgressBar: true,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
        });
    }
    successMsg=(val)=>{
        toast.info(val,  {
            theme: "colored",
            position: "top-center",
            autoClose: 1000,
            hideProgressBar: true,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
        });
    }

    onSubmit=()=>{
        var val= this.state
        if(val.amount=="" || val.amount==="0"){
            this.errorMsg("Amount is required")
        }
        if(val.type==""){
            this.errorMsg("Type is required")
        }
        if(val.amount!=="" && val.amount!=="0" && val.type!==""){
            this.setState({loading:true})
            var formd = new FormData()
            formd.append("token",this.state.token)
            formd.append("amount",this.state.amount)
            formd.append("type",this.state.type)
            Axios.post(ApiUrl.baseurl+"auth/convert",formd)
                .then(res=>{
                    if(res.data.success){
                        this.successMsg(res.data.success)
                        setTimeout(()=>{
                            this.props.history.push("/wallet/spot")
                        },1000)
                    }else {
                        this.errorMsg(res.data.error)
                    }
                    this.setState({loading:false})
                })
                .catch(err=>{
                    //console.log(err)
                    this.setState({loading:false})
                })
        }
    }


    render() {
        return (
            <>
                <TopMenu title="Convert Bankcoin Cash"/>
                <div className="convert">
                  <div className="row m-0 mt-3 mb-5">
                      <div className="col-md-3"></div>
                      <div className="col-md-6">
                          <div className="card">
                              <div className="card-body" style={{paddingBottom:"50px"}}>
                                  <h5 className="card-title mb-5 mt-3">Convert Bankcoin Cash</h5>

                                  <div className="form-group mb-3">
                                      <label htmlFor="exampleFormControlInput1">
                                          Amount To Convert
                                      </label>
                                      <input value={this.state.amount} onChange={this.amount}
                                             type="number" className="form-control shadow-none mt-1" id="exampleFormControlInput1"
                                             placeholder="0"/>
                                  </div>
                                  <div className="form-group mb-3">
                                      <label htmlFor="exampleFormControlInput1">Convert Type</label>
                                      <select onChange={this.type} className="form-control shadow-none mt-1">
                                          <option value="coin">BKC to BKC-BSC</option>
                                          <option value="token">BKC-BSC to BKC</option>
                                      </select>
                                  </div>

                                  <button disabled={this.state.loading}
                                      onClick={this.onSubmit} type="button"
                                          className="btn w-100 btn-primary mt-3 mb-5
                                           text-white shadow-none">
                                      {this.state.loading==true?"Loading...":"Convert"}
                                  </button>

                              </div>
                          </div>
                      </div>
                  </div>
                </div>
                <Footer/>
            </>
        );
    }
}

export default Convert;