import React, {Component} from 'react';
import TopMenu from "../TopMenu";
import Footer from "../Footer";
import QRCode from "react-qr-code";
import {ethers} from "ethers";
import {toast} from "react-toastify";
import axios from "axios";
import Axios from "axios";
import moment from "moment";

class AccessWallet extends Component {
    constructor() {
        super();
        this.state={
            login:false,key:"",bal:"0",amount:"",addressto:"",
            address:"",loading:false,copyad:false,
            trx:[
            ]
        }
    }

    onCopyClipboard=(val)=>{
        navigator.clipboard.writeText(val)
        this.setState({copyad:true})
        setTimeout(()=>{
            this.setState({copyad:false})
        },3000)
    }
    address=(e)=>{
        this.setState({addressto:e.target.value})
    }
    amount=(e)=>{
        this.setState({amount:e.target.value})
    }
    key=(e)=>{
        this.setState({key:e.target.value})
    }
    loadWallet=async ()=>{
       if(this.state.key==""){
           this.err("Private key is required")
       }
       if(this.state.key!==""){
           this.setState({loading:true})
           const provider =await new ethers.providers.JsonRpcProvider('https://rpc.bankcoincash.org/', { name: 'bankcoin', chainId: 1994100 })
           let wallet =await new ethers.Wallet(this.state.key,provider);
           var address = wallet.address
           const ethbalance = await provider.getBalance(address);
           console.log("BKC Balance : ",ethers.utils.formatEther(ethbalance));
           if(ethbalance){
               setTimeout(()=>{
                   this.getTrx()
               },100)
               if(this.state.login==false){
                   this.success("Your wallet successfully signed")
               }
               this.setState({address:address,bal:ethers.utils.formatEther(ethbalance),
                   login:true,loading:false})
           }else{
               this.setState({loading:false})
               this.err("Something went wrong")
           }

       }
    }

    getTrx=()=>{
        var formd = new FormData()
        Axios.get("https://scan.bankcoincash.org/api/account/trx/"+this.state.address,formd)
            .then(res=>{
                console.log(res.data.trx)
                this.setState({trx:res.data.trx})
            })
            .catch(err=>{
                //
            })
    }
    SendCoin=async ()=>{
        if(this.state.amount==""){
            this.err("Amount is required")
        }
        if(this.state.addressto==""){
            this.err("Address is required")
        }
        if(this.state.amount!=="" && this.state.addressto!==""){
            this.setState({loading:true})
            const provider =await new ethers.providers.JsonRpcProvider('https://rpc.bankcoincash.org/', { name: 'bankcoin', chainId: 1994100 })
            let wallet =await new ethers.Wallet(this.state.key,provider);
            var address =await wallet.address
            this.setState({address:address})
            const feeData = await provider.getFeeData()
            const tx = {
                from: address,
                to: this.state.addressto,
                value: ethers.utils.parseUnits(this.state.amount, 'ether').toHexString(),
                gasLimit: ethers.utils.hexlify(21000),
                gasPrice: feeData.gasPrice
            }
            const ethbalance = await provider.getBalance(address);
            if(ethers.utils.formatEther(ethbalance)>=parseFloat(this.state.amount)){
                wallet.sendTransaction(tx)
                    .then((ttx) => {
                        this.success("Transaction has been broadcast to node")
                        ttx.wait()
                            .then(res=>{
                                this.success("Transaction Send Successful trx id : "+res.transactionHash)
                                console.log(res)
                                console.log("trx hash "+res.transactionHash)
                                this.setState({loading:false})
                                this.loadWallet()
                                this.getTrx()
                            })
                            .catch(error=>{
                                //console.log(error.message)
                                this.err(error.message)
                                this.setState({loading:false})
                            })
                    })
                    .catch(error=>{
                        console.log(error.message)
                        this.err(error.message)
                        this.setState({loading:false})
                    })
            }else {
                err(" balance not enough")
                this.setState({loading:false})
            }
        }
    }


    err=(val)=>{
        toast.error(val, {
            position: "top-center",
            autoClose: 5000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
        });
    }
    success=(val)=>{
        toast.success(val, {
            position: "top-center",
            autoClose: 5000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
        });
    }


    render() {

        const trx = this.state.trx.map(res1 => {
            var localTime = moment.utc(res1.timeStamp * 1000).toDate();
            var val = res1.value / 1000000000000000000;
            return (
                <div className="blockcard">
                    <div className="row">
                        <div className="col-md-3 col-12">
                            <p><span className="txword">TX : </span><a target="_blank" style={{padding:"0px",background:"transparent",fontSize:"14px",color:"black"}} href={"https://scan.bankcoincash.org/tx/" + res1.hash}>{res1.hash.substring(0, 10)}...</a></p>
                            <p><span className="txsecond">{moment(localTime).fromNow()}</span></p>
                        </div>
                        <div className="col-md-5 col-8">
                            <p>From : <a target="_blank" style={{padding:"0px",background:"transparent",fontSize:"14px",color:"black"}} href={"https://scan.bankcoincash.org/address/" + res1.from}>{res1.from !== null ? res1.from.substring(0, 20) + "..." : "Null"}</a></p>
                            <p>To : <a target="_blank" style={{padding:"0px",background:"transparent",fontSize:"14px",color:"black"}} href={"https://scan.bankcoincash.org/address/" + res1.to}>{res1.to !== null ? res1.to.substring(0, 20) + "..." : "Null"}</a></p>
                        </div>
                        <div className="col-md-3 col-4">
                            <p className="ethval">{(res1.value / 1000000000000000000).toFixed(val > 1 ? 2 : val == 0 ? 0 : 5)} BKC</p>
                        </div>
                    </div>
                </div>
            )
        })

        return (
            <>
                <TopMenu title="Access Wallet Bankcoin Cash"/>
                <div className="web3">

                    <div className="row">
                        <div className="col-md-3"></div>
                        <div className="col-md-6" style={{textAlign:"left"}}>

                            {
                                this.state.login==true?<>
                                    <div className="card border-0" style={{width:"100%",background:"#f6f6f6"}}>
                                        <div className="card-body text-center">
                                            <div className="mb-3 mt-3 text-center" style={{borderBottom:"none"}}>
                                                <img className="text-center icomimg"
                                                     style={{left:"0",top:"0",height:"30px",
                                                         position:"relative"}} src="/icon.png"/>
                                                <h5 className="modal-title text-center w-100 mt-1 mb-1" id="exampleModalLabel">
                                                    {parseFloat(this.state.bal).toFixed(parseFloat(this.state.bal)>1?2:8)}  BKC</h5><br/>
                                            </div>
                                            <QRCode
                                                size={256}
                                                style={{ height: "auto", maxWidth: "200px", width: "150px",textAlign:'center' }}
                                                value={this.state.address}
                                                viewBox={`0 0 256 256`}
                                            />
                                            <p className="w-100 mt-3 p-0" style={{lineHeight:"10px",textAlign:"left"}} > Bankcoin Cash address
                                                <span style={{float:"right"}}>
                                                <a onClick={this.onCopyClipboard.bind(this,this.state.address)}
                                                   style={{cursor:"pointer",padding:"0x",background:"transparent",fontSize:"20px"}}>
                                                  {
                                                      this.state.copyad==false?<i className="fal fa-clone"></i>:
                                                          <i className="fas fa-check text-success"></i>
                                                  }
                                                </a>
                                          </span>
                                            </p>
                                            <p style={{float:"left",lineHeight:"15px"}} className="overflow-auto depositaddress pb-1 p-0">
                                                {this.state.address}
                                            </p>
                                            <p className="alert alert-secondary mt-5" role="alert" style={{fontSize:"12px",textAlign:"left"}}>
                                                This is a Bankcoin Cash network address.
                                                Do not send BKC over any other network to this address
                                                or your funds may be lost.<br/>
                                                After send BKC wait in wallet page 1-2 minute , if your fund not added then refresh wallet page
                                            </p>

                                            <div className="mb-3 mt-3" style={{textAlign:"left"}}>
                                                <label className="mb-3">BKC address</label>
                                                <input onChange={this.address} value={this.state.addressto} type="text" className="form-control shadow-none"
                                                       id="exampleFormControlInput1" placeholder="0x"/>
                                            </div>
                                            <div className="mb-3 mt-3" style={{textAlign:"left"}}>
                                                <label className="mb-3">Amount</label>
                                                <input onChange={this.amount} value={this.state.amount} type="number" className="form-control shadow-none"
                                                       id="exampleFormControlInput1" placeholder="0"/>
                                            </div>

                                            <button style={{padding:"15px",
                                                background:"#0F498F",borderColor:"#0F498F"}} onClick={this.SendCoin}
                                                   disabled={this.state.loading}
                                                    className="btn btn-primary shadow-none w-100 mb-5 mt-3">
                                                {this.state.loading==true?"Sending BKC...":"Send BKC"}
                                                </button>

                                        </div>

                                    </div>
                                    <br/>
                                    {trx}
                                </>:<>
                                    <h3 className="text-dark text-center">Access my wallet</h3>
                                    <div className="mb-3 mt-5">
                                        <label htmlFor="exampleFormControlTextarea1" className="form-label fw-bold mb-3">Enter your private key</label>
                                        <textarea onChange={this.key} value={this.state.key}
                                            type="password" className="form-control shadow-none" id="exampleFormControlTextarea1" rows="3"></textarea>
                                    </div>
                                    <button style={{background:"#0F498F",borderColor:"#0F498F"}} onClick={this.loadWallet} disabled={this.state.loading}
                                            className="btn btn-primary w-100 shadow-none p-3 mt-3 mb-5">
                                        {this.state.loading==true?"Loading....":
                                        "Access my wallet"
                                        }
                                    </button>
                                    <br/><br/>
                                </>
                            }

                        </div>
                    </div>
                </div>
                <Footer/>
            </>
        );
    }
}

export default AccessWallet;