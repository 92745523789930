import React, { Component } from 'react';
import bankcoinlogo from "../images/bankcoinlogo.png"
import {Link,withRouter} from "react-router-dom";
import { ToastContainer, toast } from 'react-toastify';
import jwtDecode from "jwt-decode";
import ApiUrl from "../AppUrl/ApiUrl";
import Axios from "axios";


class TopMenu extends Component {
    constructor() {
        super();
        this.state={
            sideBar:"sideBarClose",overLay:"sideLayoutClose",
            profilemenu:"profilemenuClose",profileLayout:"profileLayoutClose",
            token:"",email:"",uid:"",email2:"",
            form:false,code:"",dbcode:""
        }
    }
    componentDidMount() {
        var token = localStorage.getItem("authtoken")
        if(token){
            var decoded = jwtDecode(token)
            //console.log(decoded)
            this.setState({token:token,uid:decoded.uid,email:decoded.email})
            var email2 = decoded.email
            var mail = email2.split("@")
            //console.log(email2.split("@"))
            this.setState({email2:mail[0].substring(0,4)+"*****@"+mail[1]})
            var formd = new FormData()
            formd.append("token",token)
            Axios.post(ApiUrl.baseurl+"auth/profile",formd)
                .then(res=>{
                    if(res.data["profile_status"]=="0"){
                        console.log(res.data["profile_status"])
                        localStorage.removeItem("authtoken")
                    }
                })
                .catch(err=>{
                    //
                })
        }
    }
    menuClick=()=>{
        if(this.state.sideBar=="sideBarClose"){
            this.setState({sideBar:"sideBarOpen",overLay:"sideLayoutOpen"})
        }else{
            this.setState({sideBar:"sideBarClose",overLay:"sideLayoutClose"})
        }
    }
    profileClick=()=>{
        if(this.state.profilemenu=="profilemenuClose"){
            this.setState({profilemenu:"profilemenuOpen",profileLayout:"profileLayoutOpen"})
        }else{
            this.setState({profilemenu:"profilemenuClose",profileLayout:"profileLayoutClose"})
        }
    }
    errorMsg=(val)=>{
        toast.error(val, {
            theme: "colored",
            position: "top-center",
            autoClose: 2000,
            hideProgressBar: true,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
        });
    }
    successMsg=(val)=>{
        toast.info(val,  {
            theme: "colored",
            position: "top-center",
            autoClose: 1000,
            hideProgressBar: true,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
        });
    }

    logOut=()=>{
        localStorage.removeItem("authtoken")
        this.successMsg("Successfully Logout")
        setTimeout(()=>{
            this.props.history.push("/login")
        },500)
    }
    comingSoon=()=>{
        this.successMsg("Coming soon...")
    }
    render() {
        var val = this.state
        return (
            <>
                <div onClick={this.profileClick} className={this.state.profileLayout}></div>
                <div className={this.state.profilemenu}>
                    <div className="headp">
                        <h3>{val.email2}</h3>
                        <p>UID : {val.uid}</p>
                    </div>
                    <ul>
                        <li><Link to="/wallet">Wallet</Link></li>
                        <li><Link to="/wallet/deposit">Deposit</Link></li>
                        <li><Link to="/wallet/withdrew">Withdrew</Link></li>
                        <li><Link to="/mining">Mining</Link></li>
                        <li><Link to="/my/referral">Referral</Link></li>
                        <li><Link to="/wallet/history">Payments</Link></li>
                        <li><Link to="/change-password">Change Password</Link></li>
                        <li onClick={this.logOut} className="text-center"><Link to="#">Logout</Link></li>
                    </ul>
                </div>
                <div className="TopMenu">
                    <title>{this.props.title}</title>
                    <div className="logo">
                        <Link to="/">
                            <img src={bankcoinlogo}/>
                        </Link>
                    </div>
                    <div className="rightMenu">
                        <ul className="middlemenu">
                            <li><Link to="/web3">Web3 Wallet</Link></li>
                            <li><Link to="/airdrop">Airdrop</Link></li>
                            <li><Link to="/mining">Mining </Link></li>
                            <li><Link to="/presale">Pre-Sale</Link></li>
                            <li><Link to="/convert">Convert</Link></li>
                        </ul>
                        <ul className="lastmenu">
                            <li onClick={this.menuClick}><i className="far fa-bars"></i></li>
                            {
                                val.token?
                                    <li onClick={this.profileClick} className="usericon" style={{color:"#233C95"}}><i className="far fa-user"></i></li>
                                    :""
                            }
                            {
                                val.token==""?
                                    <>
                                        <li><Link className="btnsignin" to="/register">Sign Up</Link></li>
                                        <li><Link className="btnsignin" to="/login">Log In</Link></li>
                                    </>
                                    :<>
                                        <li>
                                            <Link to="/wallet">Wallet</Link>
                                        </li>
                                        <li>
                                            <Link to="/my/referral">Referral</Link>
                                        </li>
                                    </>
                            }

                        </ul>
                    </div>
                </div>
                <div className="menuExtra"></div>
                <ToastContainer
                    theme="colored"
                    position="top-right"
                    autoClose={1000}
                    hideProgressBar
                    newestOnTop={false}
                    closeOnClick
                    rtl={false}
                    pauseOnFocusLoss
                    draggable
                    pauseOnHover
                />
                <div className={this.state.sideBar}>
                    <div className="row m-0">
                        <div className="col-md-12">
                            <i onClick={this.menuClick} className="fas fa-times"></i>
                        </div>
                        {
                            val.token?"":
                                <>
                                    <div className="col-md-6 col-12">
                                        <Link to="/register" type="button" className="signupbtn">Sign Up</Link>
                                    </div>
                                    <div className="col-md-6 col-12">
                                        <Link to="/login" type="button" className="loginbtn">Login</Link>
                                    </div>
                                </>
                        }
                        <div className="col-md-12">
                            <ul className="">
                                <li><Link to="/web3">Web3 Wallet <i className="fas fa-sort-down float-end"></i></Link></li>
                                <li ><Link to="/airdrop">Airdrop <i className="fas fa-sort-down float-end"></i></Link></li>
                                <li><Link to="/mining">Mining <i className="fas fa-sort-down float-end"></i></Link></li>
                                <li><Link to="/presale">Pre-Sale<i className="fas fa-sort-down float-end"></i></Link></li>
                                <li><Link to="/convert">Convert<i className="fas fa-sort-down float-end"></i></Link></li>
                            </ul>
                            <p style={{marginBottom:"0",marginTop:"50px"}}>
                                Download <a target="_blank" href="/bankcoin.apk"><i className="fas fa-download float-end" style={{cursor:"pointer"}}></i></a>
                            </p>
                            <p style={{marginBottom:"0"}}>
                                English/USD <i className="fas fa-language float-end" style={{cursor:"pointer"}}></i>
                            </p>
                            <p style={{marginBottom:"0"}}>
                                Currency <i className="fas fa-globe float-end" style={{cursor:"pointer"}}></i>
                            </p>
                        </div>
                    </div>
                </div>
                <div onClick={this.menuClick} className={this.state.overLay}></div>
            </>
        );
    }
}

export default withRouter(TopMenu);
